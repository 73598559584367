import './App.css';

import '@aws-amplify/ui-react/styles.css';

import { Route, Routes, useNavigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import HomePage from './components/pages/admin/HomePage';
import Amplify, { Auth } from 'aws-amplify';
import ApplyPage from './components/pages/ApplyPage';
import AdminPanelLayout from './components/pages/admin/AdminPanelLayout';
import { useEffect } from 'react';
import ApplicationSuccessfulPage from './components/pages/ApplicationSuccessfulPage';
import { EducationApiURL } from './Variables';

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_ugMMNDk4b",
    userPoolWebClientId: "6ob2llvnueep0g0rcm98jksta",
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  API: {
    endpoints: [
      {
        name: "EducationAPI",
        endpoint: EducationApiURL,
        custom_header: async () => {
          const token = (await Auth.currentSession())?.getAccessToken()?.getJwtToken();
          if (token === undefined) return {};

          return {
            Authorization: `Bearer ${token}`
          }
        }
      }
    ]
  }
});

interface RedirectWrapperProps {
  to: string;
}

const RedirectWrapper = (props: RedirectWrapperProps) => {
  const navigate = useNavigate();
  useEffect(() => navigate(props.to), [navigate, props.to]);

  return (<div>Redirecting...</div>);
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<RedirectWrapper to='/apply' />} />
        <Route path="apply">
          <Route index element={<ApplyPage />} />
          <Route path="success" element={<ApplicationSuccessfulPage />} />
        </Route>
        <Route path="admin" element={<AdminPanelLayout />}>
          <Route index element={<HomePage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
