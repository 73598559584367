import { Button, Container, DropdownItemProps, Form, LabelProps, Message, Segment, SemanticShorthandItem } from "semantic-ui-react";
import "./ApplyPage.css";
import JangaFxLogo from "../../assets/jangafxlogo-dark.png";
import { useCallback, useEffect, useMemo } from "react";
import { SubmissionType } from "../../features/pages/admin/homePageSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { applyPageSelector, FetchStatus, IApplicationFormInput, postApplication } from "../../features/pages/applyPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const isFileValid = (inputId: string, maxSize?: number, mimeTypes?: string[]): any => {
    const idFileInput = document.getElementById(inputId) as any;

    if (idFileInput === undefined || idFileInput === null) return false;
    if (idFileInput.files?.length === 0) return false;
    if (mimeTypes !== undefined && !mimeTypes.includes(idFileInput.files[0].type)) return "The file format is not accepted!";
    if (maxSize !== undefined && idFileInput.files[0].size >= maxSize) return "The file is too big!";

    return true;
};

const ApplyPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const applyPage = useSelector(applyPageSelector);

    const { register, watch, handleSubmit, control, formState: { errors } } = useForm<IApplicationFormInput>({
        defaultValues: {
            applicationType: undefined,
            agreesTerms: false,
        },
    });

    useEffect(() => {
        if (applyPage.applicationStatus === FetchStatus.Fulfilled) {
            navigate("/apply/success");
        }
    }, [applyPage.applicationStatus, navigate]);

    const onSubmit: SubmitHandler<IApplicationFormInput> = useCallback(data => {
        dispatch(postApplication(data));
    }, [dispatch]);

    const appTypeOptions = useMemo<DropdownItemProps[]>(() => [
        {
            text: "Student",
            value: SubmissionType.Student,
        },
        {
            text: "Faculty Member",
            value: SubmissionType.FacultyMembers,
        }, {
            text: "University (Seeking multiple licenses)",
            value: SubmissionType.University,
        },
    ], []);

    const reasonErrors = useMemo<boolean | SemanticShorthandItem<LabelProps>>(() => {
        if (errors.reason?.type === "required") return true;

        if (errors.reason?.type === "minLength") {
            return {
                content: "The reason must contain at least 10 characters.",
                pointing: "below"
            };
        }

        if (errors.reason?.type === "maxLength") {
            return {
                content: "The reason must contain at most 1000 characters.",
                pointing: "below"
            };
        }

        return false;
    }, [errors.reason?.type]);

    const extraMessageErrors = useMemo<boolean | SemanticShorthandItem<LabelProps>>(() => {
        if (errors.message?.type === "maxLength") {
            return {
                content: "The extra message must contain at most 1000 characters.",
                pointing: "below"
            };
        }

        return false;
    }, [errors.message?.type]);

    const idFileErrors = useMemo<boolean | SemanticShorthandItem<LabelProps>>(() => {
        if (errors.identification?.type === "required") return true;

        if (errors.identification?.type === "validate") {
            return errors.identification?.message;
            /* return {
                content: errors.identification?.message ?? "Invalid file input.",
                pointing: "below"
            }; */
        }

        return false;
    }, [errors.identification]);

    return (
        <div id="applyPageContainer">
            <Container>
                <div id="formContainer">
                    <img src={JangaFxLogo} alt="JangaFX Logo" />
                    <h2 style={{ textAlign: "center" }}>Educational Licensing Inquiry form</h2>
                    <p>
                        As a student or faculty member you can apply for an educational discount on our JangaFX Suite,
                        with licenses which are valid for one year (renewable). This license should be used for personal learning purposes only.
                        For school/teaching usage, please select "university" from the "application type" dropdown below when filling out the form if you are a faculty member.
                    </p>
                    <Form onSubmit={handleSubmit(onSubmit)} loading={applyPage.applicationStatus === FetchStatus.Pending}>
                        <Form.Group widths={"2"}>
                            <Form.Field error={errors.fullName !== undefined}>
                                <label>Full Name <span className="important">*</span></label>
                                <input {...register("fullName", { required: true, maxLength: 50 })} placeholder='Full name...' />
                            </Form.Field>
                            <Form.Field error={errors.email !== undefined}>
                                <label>Your E-Mail <i>(Student email if applicable)</i> <span className="important">*</span></label>
                                <input {...register("email", { required: true, maxLength: 100 })} placeholder='Your E-Mail...' type="email" />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={"2"}>
                            <Form.Field error={errors.universityName !== undefined}>
                                <label>University Name <span className="important">*</span></label>
                                <input {...register("universityName", { required: true })} placeholder='University Name...' />
                            </Form.Field>
                            <Form.Field error={errors.universityMajor !== undefined}>
                                <label>University Major <span className="important">*</span></label>
                                <input {...register("universityMajor", { required: true })} placeholder='University Major...' />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={"2"}>
                            <Form.Field error={errors.universityCity !== undefined}>
                                <label>University City <span className="important">*</span></label>
                                <input {...register("universityCity", { required: true })} placeholder='University City...' />
                            </Form.Field>
                            <Form.Field error={errors.universityCountry !== undefined}>
                                <label>University Country <span className="important">*</span></label>
                                <input {...register("universityCountry", { required: true })} placeholder='University Country...' />
                            </Form.Field>
                        </Form.Group>
                        <Controller
                            name="reason"
                            control={control}
                            rules={{ required: true, minLength: 10, maxLength: 1000 }}
                            defaultValue=""
                            render={({ field }) =>
                                <Form.TextArea
                                    {...field}
                                    placeholder="Application reason... (min. 10 characters)"
                                    label={<label>Why are you applying for an educational license? What do you plan on creating? <span className="important">*</span></label>}
                                    error={reasonErrors}
                                />
                            } />
                        <Form.Field>
                            <label>Application type <span className="important">*</span></label>
                            <label>{JSON.stringify(errors.applicationType)}</label>
                            <select className="ui fluid dropdown" {...register("applicationType", { required: true })}>
                                {appTypeOptions.map(o => (
                                    <option value={o.value?.toString()}>
                                        {o.text}
                                    </option>
                                ))}
                            </select>
                        </Form.Field>
                        {watch("applicationType") == SubmissionType.University &&
                            <Message color="yellow">
                                <Message.Header>More information required!</Message.Header>
                                <p>Please specify the amount of licenses you need in the 'extra message' section. Detail your application as much as you can to ease the process of being accepted.</p>
                            </Message>}
                        <Controller
                            name="message"
                            control={control}
                            rules={{ maxLength: 1000 }}
                            defaultValue=""
                            render={({ field }) =>
                                <Form.TextArea
                                    {...field}
                                    placeholder="Type here any extra information that might be relevation to this application..."
                                    label={<label>Extra message</label>}
                                    error={extraMessageErrors}
                                />
                            } />
                        <Form.Field error={idFileErrors}>
                            <label>School ID Card Photo <i>(max. 5MB)</i> <span className="important">*</span></label>
                            <small>Please attach a copy of a VALID student card or certificate. Your NAME and the CURRENT YEAR should be clearly visible.</small>

                            <input id="idFileInput"
                                {...register("identification", {
                                    required: true,
                                    validate: _ => isFileValid("idFileInput", 5 * 1000 * 1000, ["image/jpeg", "image/png", "application/pdf"]),
                                })}
                                type="file"
                                accept="image/jpeg, image/png, application/pdf" />

                            {errors.identification?.type === "validate" &&
                                <div className="ui pointing red label">
                                    {errors.identification.message}
                                </div>}
                        </Form.Field>
                        <Form.Field inline
                            error={errors.agreesTerms?.type === "required" ? true : false}>
                            <div className="ui checkbox">
                                <input type="checkbox" style={{ marginRight: "10px" }} {...register("agreesTerms", { required: true })} />
                                <label><span>I agree to the <a href="https://jangafx.com/legal/end-user-license-agreement/" target="_blank" rel="noreferrer">Terms and Conditions</a>.</span></label>
                            </div>
                        </Form.Field>
                        {(applyPage.applicationStatus === FetchStatus.Idle
                            || applyPage.applicationStatus === FetchStatus.Pending)
                            &&
                            <Button fluid type="submit">Apply</Button>}
                        {applyPage.applicationStatus === FetchStatus.Failed &&
                            (
                                <>
                                    <Segment basic color="red">
                                        <p>Something went wrong while sending application... Try again later.</p>
                                        <p>Please contact us at <a href="mailto:support@jangafx.com">support@jangafx.com</a></p>
                                    </Segment>
                                    <Button fluid type="submit" disabled color="red">Failed</Button>
                                </>
                            )}
                    </Form>
                </div>
            </Container >
        </div >
    );
};

export default ApplyPage;