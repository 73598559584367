import { withAuthenticator } from "@aws-amplify/ui-react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Container, Dropdown, Popup } from "semantic-ui-react";
import JangaFxLogo from "../../../assets/jangafxlogo-dark.png";
import "./AdminPanelLayout.css";

interface UserData {
    name: string;
    email: string;
}

const AdminPanelLayout = ({ signOut, user }: { signOut: any, user: CognitoUser }) => {
    const [userData, setUserData] = useState<UserData | undefined>();
    useEffect(() => {
        user.getUserAttributes((err, attributes) => {
            const name = attributes?.filter(v => v.Name === "name")?.[0]?.Value ?? "Unknown";
            const email = attributes?.filter(v => v.Name === "email")?.[0]?.Value ?? "Unknown e-mail";

            setUserData({
                name: name,
                email: email
            })
        });
    }, [user]);

    return (
        <>
            <div id="adminNavBar">
                <Container>
                    <div className="content">
                        <img src={JangaFxLogo} alt="JangaFX Logo" className="logo" />
                        <Dropdown item text={`Hello ${userData ? userData.name : "user"}`} className="user-actions">
                            <Dropdown.Menu>
                                <Dropdown.Header>Actions</Dropdown.Header>
                                <Dropdown.Item onClick={() => signOut()}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Container>
            </div>
            <Outlet />
        </>
    )
};

export default withAuthenticator(AdminPanelLayout);