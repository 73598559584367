import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { EducationApiURL } from "../../Variables";
import { SubmissionType } from "./admin/homePageSlice";

export interface IApplicationFormInput {
    fullName: string;
    email: string;
    universityName: string;
    universityMajor: string;
    universityCity: string;
    universityCountry: string;
    reason: string;
    message?: string;
    applicationType: SubmissionType | undefined;
    identification: FileList;
    agreesTerms: boolean;
}

export enum FetchStatus {
    Idle = "idle",
    Pending = "pending",
    Fulfilled = "fulfilled",
    NonInitialized = "non-initialized",
    Failed = "failed",
}

interface ApplyPageState {
    applicationStatus: FetchStatus;
}

const initialState: ApplyPageState = {
    applicationStatus: FetchStatus.Idle
};

/*
 * Thunks
 */
export const postApplication = createAsyncThunk<
    void,
    IApplicationFormInput,
    any
>('applyPage/postApplication',
    async (args, thunkApi) => {
        const fd = new FormData();
        fd.append("fullName", args.fullName);
        fd.append("email", args.email);
        fd.append("universityName", args.universityName);
        fd.append("universityCity", args.universityCity);
        fd.append("universityCountry", args.universityCountry);
        fd.append("universityCourse", args.universityMajor);
        fd.append("reason", args.reason);
        fd.append("message", args.message ?? "");
        fd.append("submissionType", args.applicationType?.toString() ?? "");
        fd.append("identificationFile", args.identification[0]);

        try {
            const resp = await fetch(`${EducationApiURL}/submissions`, {
                method: "post",
                body: fd
            });

            if(!resp.ok) {
                return thunkApi.rejectWithValue("An error occured while submitting the application.");
            }
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }
    });

const applyPageSlice = createSlice({
    name: "features/pages/applyPage",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(postApplication.fulfilled, (state, action) => {
                state.applicationStatus = FetchStatus.Fulfilled;
            })
            .addCase(postApplication.pending, (state, action) => {
                state.applicationStatus = FetchStatus.Pending;
            })
            .addCase(postApplication.rejected, (state, action) => {
                state.applicationStatus = FetchStatus.Failed;
            });
    }

});

export const applyPageSelector = createSelector(
    (state: RootState) => state.applyPage,
    (data) => data,
);

export default applyPageSlice.reducer;