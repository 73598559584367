import { Container } from 'semantic-ui-react';
import './ApplicationSuccessfulPage.css';

const ApplicationSuccessfulPage = () => {
    return (
        <div id="applicationSuccessfulPageContainer">
            <Container>
                <p style={{ textAlign: "center" }}>
                    Your application has been successfully submitted, you'll be contacted in a couple of days by the JangaFX team with a response to your application.
                </p>
            </Container>
        </div>
    )
};

export default ApplicationSuccessfulPage;