import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "../../../store";

export enum FetchStatus {
    Idle = "idle",
    Pending = "pending",
    Fulfilled = "fulfilled",
    NonInitialized = "non-initialized",
    Failed = "failed",
}

export enum SubmissionType {
    Student = 1,
    FacultyMembers = 2,
    University = 3,
}

export enum SubmissionStatus {
    Pending = 0,
    Approved = 1,
    Denied = 2
}

export interface Submission {
    id: string;
    name: string;
    email: string;
    message?: string;
    universityName: string;
    universityCity: string;
    universityCountry: string;
    universityCourse: string;
    reason?: string;
    identificationUrl: string;
    doneAt?: string;
    approvedAt?: string;
    createdAt: string;
    updatedAt: string;
    submissionType: SubmissionType;
    submissionStatus: SubmissionStatus;
    couponCode: string;
}

interface PageInfo {
    perPageCount: number;
    page: number;
    totalPages: number;
    nextPage?: number;
    previousPage?: number;
}

interface SubmissionsFilters {
    type: SubmissionType;
    status: SubmissionStatus;
    searchString: string;
}

interface Statistics {
    generatedAt: string;
    pendingSubmissions: number;
    totalSubmissions: number;
    approvedRatio: number;
    last7DaysSubmissions: number;
    last30DaysSubmissions: number;
}

interface HomePageState {
    submissions: {
        data: Submission[];
        pageInfo: PageInfo;
        filters: SubmissionsFilters;
        fetchStatus: FetchStatus;
        actionPendingIds: string[];
    };

    statistics: {
        data: Statistics;
        fetchStatus: FetchStatus;
    }
}

const initialState: HomePageState = {
    submissions: {
        data: [],
        pageInfo: {
            page: 0,
            perPageCount: 0,
            totalPages: 0,
        },
        filters: {
            type: SubmissionType.FacultyMembers,
            status: SubmissionStatus.Pending,
            searchString: "",
        },
        fetchStatus: FetchStatus.Idle,
        actionPendingIds: [],
    },

    statistics: {
        data: {
            approvedRatio: 0,
            generatedAt: "",
            last30DaysSubmissions: 0,
            last7DaysSubmissions: 0,
            pendingSubmissions: 0,
            totalSubmissions: 0,
        },
        fetchStatus: FetchStatus.NonInitialized
    }
};

/*
 * Thunks
 */
type FetchSubmissionsReturnType = {
    data: Submission[];
    pageInfo: PageInfo;
};

export const fetchSubmissions = createAsyncThunk<
    FetchSubmissionsReturnType,
    { page: number, perPage: number, type: SubmissionType, status: SubmissionStatus, searchString: string },
    any
>('submissions/fetchSubmissions',
    async (args, thunkApi) => {
        let response: FetchSubmissionsReturnType;
        try {
            response = await API.get("EducationAPI", "/submissions", {
                queryStringParameters: {
                    page: args.page,
                    count: args.perPage,
                    type: args.type,
                    status: args.status,
                    searchString: args.searchString,
                }
            }) as FetchSubmissionsReturnType;
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }

        return response
    });

export const approveSubmission = createAsyncThunk<
    string | undefined,
    { submissionId: string },
    any
>('submissions/approveSubmission',
    async (args, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        try {
            await API.post("EducationAPI", "/submissions/approve", {
                queryStringParameters: {
                    submissionId: args.submissionId
                }
            });
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }

        thunkApi.dispatch(
            fetchSubmissions({
                page: state.admin.homePage.submissions.pageInfo.page,
                perPage: state.admin.homePage.submissions.pageInfo.perPageCount,
                type: state.admin.homePage.submissions.filters.type,
                status: state.admin.homePage.submissions.filters.status,
                searchString: state.admin.homePage.submissions.filters.searchString,
            })
        );

        return args.submissionId
    });

export const denySubmission = createAsyncThunk<
    string | undefined,
    { submissionId: string },
    any
>('submissions/denySubmission',
    async (args, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        try {
            await API.post("EducationAPI", "/submissions/deny", {
                queryStringParameters: {
                    submissionId: args.submissionId
                }
            });
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }

        thunkApi.dispatch(
            fetchSubmissions({
                page: state.admin.homePage.submissions.pageInfo.page,
                perPage: state.admin.homePage.submissions.pageInfo.perPageCount,
                type: state.admin.homePage.submissions.filters.type,
                status: state.admin.homePage.submissions.filters.status,
                searchString: state.admin.homePage.submissions.filters.searchString,
            })
        );

        return args.submissionId;
    });

export const deleteSubmission = createAsyncThunk<
    string,
    { submissionId: string },
    any
>('submissions/deleteSubmission',
    async (args, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        try {
            await API.del("EducationAPI", `/submissions/${args.submissionId}`, {});
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }

        thunkApi.dispatch(
            fetchSubmissions({
                page: state.admin.homePage.submissions.pageInfo.page,
                perPage: state.admin.homePage.submissions.pageInfo.perPageCount,
                type: state.admin.homePage.submissions.filters.type,
                status: state.admin.homePage.submissions.filters.status,
                searchString: state.admin.homePage.submissions.filters.searchString,
            })
        );

        return args.submissionId;
    });


type FetchStatisticsReturnType = Statistics | undefined;
export const fetchStatistics = createAsyncThunk<
    FetchStatisticsReturnType,
    void,
    any
>('submissions/stats',
    async (args, thunkApi) => {
        let response: Statistics;

        try {
            response = await API.get("EducationAPI", "/submissions/stats", {}) as Statistics;
        } catch (error) {
            return thunkApi.rejectWithValue("Impossible to fetch data from server");
        }

        return response;
    });

/*
 * Slices
 */
const homePageSlice = createSlice({
    name: "features/pages/admin/homePage",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSubmissions.fulfilled, (state, action) => {
                state.submissions.data = action.payload.data;
                state.submissions.pageInfo = action.payload.pageInfo;
                state.submissions.filters = {
                    ...state.submissions.filters
                };

                state.submissions.fetchStatus = FetchStatus.Fulfilled;
            })
            .addCase(fetchSubmissions.pending, (state, action) => {
                state.submissions.fetchStatus = FetchStatus.Pending;
                state.submissions.filters = {
                    status: action.meta.arg.status,
                    type: action.meta.arg.type,
                    searchString: action.meta.arg.searchString,
                }
            })
            .addCase(fetchSubmissions.rejected, (state) => {
                state.submissions.fetchStatus = FetchStatus.Failed;
            })

            .addCase(approveSubmission.fulfilled, (state, action) => {
                state.submissions.actionPendingIds = state.submissions.actionPendingIds.filter(s => action.meta.arg.submissionId);
            })
            .addCase(approveSubmission.pending, (state, action) => {
                state.submissions.actionPendingIds = [...state.submissions.actionPendingIds, action.meta.arg.submissionId];
            })
            .addCase(approveSubmission.rejected, (state, action) => {
            })

            .addCase(denySubmission.fulfilled, (state, action) => {
                state.submissions.actionPendingIds = state.submissions.actionPendingIds.filter(s => action.meta.arg.submissionId);
            })
            .addCase(denySubmission.pending, (state, action) => {
                state.submissions.actionPendingIds = [...state.submissions.actionPendingIds, action.meta.arg.submissionId];
            })
            .addCase(denySubmission.rejected, (state, action) => {
            })

            .addCase(deleteSubmission.fulfilled, (state, action) => {
                state.submissions.actionPendingIds = state.submissions.actionPendingIds.filter(s => action.meta.arg.submissionId);
            })
            .addCase(deleteSubmission.pending, (state, action) => {
                state.submissions.actionPendingIds = [...state.submissions.actionPendingIds, action.meta.arg.submissionId];
            })
            .addCase(deleteSubmission.rejected, (state, action) => {
            })

            .addCase(fetchStatistics.fulfilled, (state, action) => {
                state.statistics.fetchStatus = FetchStatus.Fulfilled;
                state.statistics.data = action.payload ?? {
                    approvedRatio: 0,
                    generatedAt: "Unknown",
                    last30DaysSubmissions: 0,
                    last7DaysSubmissions: 0,
                    pendingSubmissions: 0,
                    totalSubmissions: 0,
                };
            })
            .addCase(fetchStatistics.pending, (state, action) => {
                state.statistics.fetchStatus = FetchStatus.Pending;
            })
            .addCase(fetchStatistics.rejected, (state, action) => {
                state.statistics.fetchStatus = FetchStatus.Failed;
            });
    }
});

/*
 * Selectors
 */
export const submissionsSelector = createSelector(
    (state: RootState) => state.admin.homePage,
    (data) => data
);

export const { } = homePageSlice.actions;
export default homePageSlice.reducer;