import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Divider, DropdownItemProps, Grid, Icon, Input, Menu, MenuItemProps, Modal, Placeholder, Popup, Select, Statistic, Table } from "semantic-ui-react";
import './HomePage.css';
import { approveSubmission, deleteSubmission, denySubmission, fetchStatistics, FetchStatus, fetchSubmissions, Submission, submissionsSelector, SubmissionStatus, SubmissionType } from "../../../features/pages/admin/homePageSlice";
import moment from "moment";

interface InterestContext {
    lastClickedSubmissionId?: string;
}

const AdditionalInfoModal = (props: {
    submission: Submission
}) => {
    return (
        <div style={{ padding: "15px" }}>
            <h3>Reason of application</h3>
            <p>{props.submission.reason}</p>
            {props.submission.message &&
                <>
                    <Divider />
                    <h3>Message from {props.submission.name}</h3>
                    <p>{props.submission.message}</p>
                </>}
        </div>
    );
};

const HomePage = () => {
    const [interestContext, setInterestContext] = useState<InterestContext>({});
    const [searchText, setSearchText] = useState<string>("");

    const homePage = useSelector(submissionsSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (searchText === "") {
            dispatch(fetchSubmissions({
                page: 1,
                perPage: 20,
                type: homePage.submissions.filters.type,
                status: homePage.submissions.filters.status,
                searchString: "",
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        dispatch(fetchStatistics());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchSubmissions({
            page: 1,
            perPage: 20,
            type: homePage.submissions.filters.type,
            status: homePage.submissions.filters.status,
            searchString: homePage.submissions.filters.searchString,
        }));
    }, [dispatch, homePage.submissions.filters.searchString, homePage.submissions.filters.status, homePage.submissions.filters.type]);

    const onPreviousPageClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
        if (homePage.submissions.pageInfo.previousPage !== null) {
            dispatch(fetchSubmissions({
                page: homePage.submissions.pageInfo.previousPage ?? 1,
                perPage: 20,
                type: homePage.submissions.filters.type,
                status: homePage.submissions.filters.status,
                searchString: homePage.submissions.filters.searchString,
            }));
        }
    }, [dispatch, homePage.submissions.filters.searchString, homePage.submissions.filters.status, homePage.submissions.filters.type, homePage.submissions.pageInfo.previousPage]);

    const onNextPageClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
        if (homePage.submissions.pageInfo.nextPage !== null) {
            dispatch(fetchSubmissions({
                page: homePage.submissions.pageInfo.nextPage ?? 1,
                perPage: 20,
                type: homePage.submissions.filters.type,
                status: homePage.submissions.filters.status,
                searchString: homePage.submissions.filters.searchString,
            }));
        }
    }, [dispatch, homePage.submissions.filters.searchString, homePage.submissions.filters.status, homePage.submissions.filters.type, homePage.submissions.pageInfo.nextPage]);

    const majorOptions: DropdownItemProps[] = useMemo(() => [
        {
            value: SubmissionType.Student,
            text: "Student"
        },
        {
            value: SubmissionType.FacultyMembers,
            text: "Faculty Member"
        },
        {
            value: SubmissionType.University,
            text: "University"
        }
    ], []);

    const statusOptions: DropdownItemProps[] = useMemo(() => [
        {
            value: SubmissionStatus.Pending,
            text: "Pending",
        },
        {
            value: SubmissionStatus.Approved,
            text: "Approved",
        },
        {
            value: SubmissionStatus.Denied,
            text: "Denied",
        },
    ], []);

    const onMajorSelected = useCallback((value: SubmissionType) => {
        dispatch(fetchSubmissions({
            page: homePage.submissions.pageInfo.page,
            perPage: homePage.submissions.pageInfo.perPageCount,
            status: homePage.submissions.filters.status,
            type: value,
            searchString: homePage.submissions.filters.searchString,
        }));
    }, [dispatch, homePage.submissions.filters.searchString, homePage.submissions.filters.status, homePage.submissions.pageInfo.page, homePage.submissions.pageInfo.perPageCount]);

    const onStatusSelected = useCallback((value: SubmissionStatus) => {
        dispatch(fetchSubmissions({
            page: homePage.submissions.pageInfo.page,
            perPage: homePage.submissions.pageInfo.perPageCount,
            status: value,
            type: homePage.submissions.filters.type,
            searchString: homePage.submissions.filters.searchString,
        }));
    }, [dispatch, homePage.submissions.filters.searchString, homePage.submissions.filters.type, homePage.submissions.pageInfo.page, homePage.submissions.pageInfo.perPageCount]);

    const onSearchClicked = useCallback(() => {
        dispatch(fetchSubmissions({
            page: homePage.submissions.pageInfo.page,
            perPage: homePage.submissions.pageInfo.perPageCount,
            status: homePage.submissions.filters.status,
            type: homePage.submissions.filters.type,
            searchString: searchText,
        }));
    }, [dispatch, homePage.submissions.filters.status, homePage.submissions.filters.type, homePage.submissions.pageInfo.page, homePage.submissions.pageInfo.perPageCount, searchText]);

    return (
        <Container>
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Statistic.Group size="mini">
                    <Statistic>
                        <Statistic.Value>
                            {homePage.statistics.fetchStatus === FetchStatus.Fulfilled
                                ? homePage.statistics.data.totalSubmissions
                                : "..."}
                        </Statistic.Value>
                        <Statistic.Label>Total</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {homePage.statistics.fetchStatus === FetchStatus.Fulfilled
                                ? homePage.statistics.data.pendingSubmissions
                                : "..."}
                        </Statistic.Value>
                        <Statistic.Label>Pending</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {homePage.statistics.fetchStatus === FetchStatus.Fulfilled
                                ? Number(homePage.statistics.data.approvedRatio).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 1 })
                                : "..."}
                        </Statistic.Value>
                        <Statistic.Label>Approval Rate</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {homePage.statistics.fetchStatus === FetchStatus.Fulfilled
                                ? homePage.statistics.data.last7DaysSubmissions
                                : "..."}
                        </Statistic.Value>
                        <Statistic.Label>New last 7 days</Statistic.Label>
                    </Statistic>
                    <Statistic>
                        <Statistic.Value>
                            {homePage.statistics.fetchStatus === FetchStatus.Fulfilled
                                ? homePage.statistics.data.last30DaysSubmissions
                                : "..."}
                        </Statistic.Value>
                        <Statistic.Label>New last 30 days</Statistic.Label>
                    </Statistic>
                    <Popup position="top center"
                        content={
                            <div>
                                <p>Statistics generated at {new Date(homePage.statistics.data.generatedAt).toLocaleString()}</p>
                            </div>
                        }
                        trigger={<Icon name="question circle" />} />
                </Statistic.Group>
            </div>
            <Divider />
            <Grid columns={4} stackable>
                <Grid.Column>
                    <Select fluid placeholder="Select major type" options={majorOptions} value={homePage.submissions.filters.type} onChange={(_, data) => onMajorSelected(data.value as SubmissionType)} />
                </Grid.Column>
                <Grid.Column>
                    <Select fluid placeholder="Select status" options={statusOptions} value={homePage.submissions.filters.status} onChange={(_, data) => onStatusSelected(data.value as SubmissionStatus)} />
                </Grid.Column>
                <Grid.Column />
                <Grid.Column>
                    <Input icon={<Button style={{ marginLeft: '5px' }} icon="search" onClick={_ => onSearchClicked()} />} placeholder='Search by email...' fluid onChange={(_, data) => setSearchText(data.value)} />
                </Grid.Column>
            </Grid>
            <Table compact="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Major</Table.HeaderCell>
                        <Table.HeaderCell>University</Table.HeaderCell>
                        <Table.HeaderCell>Identification</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {homePage.submissions.fetchStatus === FetchStatus.Pending &&
                        <Table.Row>
                            <Table.Cell colSpan="6">
                                <Placeholder fluid>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Table.Cell>
                        </Table.Row>}

                    {homePage.submissions.fetchStatus === FetchStatus.Fulfilled && homePage.submissions.data.length > 0 &&
                        homePage.submissions.data.map(s => (
                            <Table.Row key={s.id} disabled={homePage.submissions.actionPendingIds.includes(s.id)} className={`submissionRow ${interestContext.lastClickedSubmissionId === s.id ? "highlight" : ""}`}>
                                <Table.Cell>
                                    <Popup
                                        position="top center"
                                        content={() => {
                                            const createdAt = moment(s.createdAt);
                                            return (
                                                <>
                                                    <p style={{ textAlign: "center" }}>{createdAt.format("LLLL")}</p>
                                                    <hr />
                                                    <p>{createdAt.fromNow()}</p>
                                                </>
                                            )
                                        }}
                                        trigger={<Icon name="calendar outline" />} />
                                    {s.name}
                                </Table.Cell>
                                <Table.Cell>{s.email}</Table.Cell>
                                <Table.Cell>{s.universityCourse}</Table.Cell>
                                <Table.Cell>{s.universityName} <br />({s.universityCity} / {s.universityCountry})</Table.Cell>
                                <Table.Cell>
                                    {homePage.submissions.filters.status !== SubmissionStatus.Pending &&
                                        <Button disabled>No longer available</Button>}

                                    {homePage.submissions.filters.status === SubmissionStatus.Pending &&
                                        <Button as="a" href={s.identificationUrl} target="_blank"
                                            onClick={() => { setInterestContext({ lastClickedSubmissionId: s.id }) }}
                                            disabled={homePage.submissions.actionPendingIds.includes(s.id)}>
                                            View
                                        </Button>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid columns={homePage.submissions.filters.status === SubmissionStatus.Pending ? 4 : 2} style={{ paddingRight: "10px" }}>
                                        <Grid.Row>
                                            {homePage.submissions.filters.status === SubmissionStatus.Pending &&
                                                <>
                                                    <Grid.Column>
                                                        <Popup
                                                            on={"click"}
                                                            trigger={
                                                                <Button icon color="green" disabled={homePage.submissions.actionPendingIds.includes(s.id)}>
                                                                    <Icon name='checkmark' />
                                                                </Button>
                                                            }
                                                            content={
                                                                <Button
                                                                    onClick={() => dispatch(approveSubmission({ submissionId: s.id }))}
                                                                    color="green">
                                                                    Confirm Approval
                                                                </Button>
                                                            }
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <Popup
                                                            on={"click"}
                                                            trigger={
                                                                <Button icon color="red" disabled={homePage.submissions.actionPendingIds.includes(s.id)}>
                                                                    <Icon name='dont' />
                                                                </Button>
                                                            }
                                                            content={
                                                                <Button
                                                                    onClick={() => dispatch(denySubmission({ submissionId: s.id }))}
                                                                    color="red">
                                                                    Confirm Denial
                                                                </Button>
                                                            }
                                                        />
                                                    </Grid.Column>
                                                </>}

                                            <Grid.Column>
                                                <Modal
                                                    trigger={<Button icon><Icon name='question' /></Button>}
                                                    header='Additional information'
                                                    content={<AdditionalInfoModal submission={s} />}
                                                    actions={[{ key: 'done', content: 'Done', positive: true }]}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Popup
                                                    on={"click"}
                                                    trigger={
                                                        <Button icon disabled={homePage.submissions.actionPendingIds.includes(s.id)}>
                                                            <Icon name='trash' />
                                                        </Button>
                                                    }
                                                    content={
                                                        <Button
                                                            onClick={() => dispatch(deleteSubmission({ submissionId: s.id }))}
                                                            color="red">
                                                            Confirm Delete
                                                        </Button>
                                                    }
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    {homePage.submissions.fetchStatus === FetchStatus.Fulfilled && homePage.submissions.data.length === 0 &&
                        <Table.Row>
                            <Table.Cell colSpan="6">
                                <p style={{ textAlign: "center" }}>Nice job! There are no submissions to validate.</p>
                            </Table.Cell>
                        </Table.Row>}

                    {homePage.submissions.fetchStatus === FetchStatus.Failed &&
                        <Table.Row>
                            <Table.Cell colSpan="6">
                                <p style={{ textAlign: "center" }}>Something went wrong... Reload the page or try again later.</p>
                            </Table.Cell>
                        </Table.Row>}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Menu floated="right" pagination>
                                <Menu.Item as='a' icon disabled={homePage.submissions.pageInfo.previousPage === undefined} onClick={onPreviousPageClicked}>
                                    <Icon name='chevron left' />
                                </Menu.Item>
                                <Menu.Item as='a'>{homePage.submissions.pageInfo.page}/{homePage.submissions.pageInfo.totalPages}</Menu.Item>
                                <Menu.Item as='a' icon disabled={homePage.submissions.pageInfo.nextPage === undefined} onClick={onNextPageClicked}>
                                    <Icon name='chevron right' />
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Container >
    );
};

export default HomePage;