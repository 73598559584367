import { combineReducers, configureStore } from "@reduxjs/toolkit";
import "./features/pages/admin/homePageSlice";
import homePageSlice from "./features/pages/admin/homePageSlice";
import applyPageSlice from "./features/pages/applyPageSlice";

const store = configureStore({
    reducer: {
        admin: combineReducers({
            homePage: homePageSlice
        }),
        applyPage: applyPageSlice
    }
});

export type RootState = ReturnType<typeof store.getState>;

export default store;